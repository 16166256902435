<template>
  <div
    v-if="customer"
    class="customer-detail"
  >
    <b-container>
      <b-row class="mb-1">
        <b-col>
          <h2 class="text-primary">
            Details client
          </h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-card
            bg-variant="light"
            class="mb-4"
          >
            <b-card-title class="mb-3">
              Informations générales
            </b-card-title>
            <b-list-group flush>
              <b-list-group-item><strong>Nom Entreprise:</strong> {{ customer.info.company_name }}</b-list-group-item>
              <b-list-group-item><strong>Email:</strong> {{ customer.info.email }}</b-list-group-item>
              <b-list-group-item><strong>Téléphone Mobile:</strong> {{ customer.info.mobile }}</b-list-group-item>
              <b-list-group-item><strong>Téléphone Fix:</strong> {{ customer.info.phone }}</b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card
            bg-variant="light"
            class="mb-4"
          >
            <b-card-title class="mb-3">
              Informations supplémentaires
            </b-card-title>
            <b-list-group flush>
              <b-list-group-item><strong>Adresse:</strong> {{ customer.info.address }}</b-list-group-item>
              <b-list-group-item><strong>Site web:</strong> {{ customer.info.website }}</b-list-group-item>
              <b-list-group-item><strong>Matricule Fiscal:</strong> {{ customer.info.tax_identification_number }}</b-list-group-item>
              <b-list-group-item><strong>Type:</strong> {{ customer.info.type }}</b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col>
          <h3 class="text-primary">
            Paiements
          </h3>
        </b-col>
      </b-row>

      <b-row>

        <b-col>
          <b-card
            bg-variant="light"
            class="mb-4"
          >
            <b-table
              striped
              hover
              responsive
              :items="customer.payments"
              :fields="paymentFields"
              class="payment-table"
            >
              <template #cell(amount)="data">
                <span class="font-weight-bold">{{ formatCurrency(data.item.amount) }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-1 mt-1">
        <b-col>
          <h3 class="text-primary">
            Factures
          </h3>
        </b-col>
      </b-row>
      <div>
        <b-card>
          <b-row>
            <b-col>
              <b-form-group
                label="Numéro"
                label-for="Numéro"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="num"
                    placeholder="Chercher numéro"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <label for="status-select">Status</label>
              <v-select
                id="status-select"
                v-model="stausPayment"
                :clearable="false"
                placeholder="Status payment"
                :options="stausPayments"
                label="label"
                @input="getFactures(1)"
              />
            </b-col>
            <b-col class="m-2">
              <b-button
                variant="gradient-info"
                class="ml-1 d-inline-block"
                @click.prevent="resetFilters()"
              >
                <span class="ml-1">Reset Filter</span>
              </b-button>
            </b-col>
          </b-row>
          <div
            v-if="isLoading === 'true'"
            class="text-center mb-2"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="factures"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :row-style-class="getRowClass"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Name -->
              <div
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar
                  :src="props.row.avatar"
                  class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </div>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  v-if="props.row.status === 'vente direct'"
                  variant="light-warning"
                >
                  <span>Vente direct</span>
                </b-badge>
                <b-badge
                  v-if="props.row.status === 'vente en ligne'"
                  variant="light-info"
                >
                  <span>Vente en ligne</span>
                </b-badge>
                <b-badge
                  v-if="props.row.status === 'vente depot'"
                  variant="light-info"
                >
                  <span>Vente depot</span>
                </b-badge>
                <b-badge
                  v-if="props.row.status === 'vente gros'"
                  variant="light-success"
                >
                  <span>Vente Gros</span>
                </b-badge>
              </span>

              <!-- Column: Payment Status -->
              <span v-else-if="props.column.field === 'payment_status'">
                <b-badge
                  :variant="getPaymentStatusVariant(props.row.payment_status)"
                  class="cursor-pointer"
                  @click=" $router.push({ name: 'facture-payment-show', params: { id: props.row.id } })"
                >
                  {{ props.row.payment_status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-trash-icon`"
                    icon="TrashIcon"
                    class="cursor-pointer"
                    size="16"
                    @click="deleteFacture(props.row.id)"
                  />
                  <b-tooltip
                    title="Supprimer"
                    :target="`invoice-row-${props.row.id}-trash-icon`"
                  />

                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="EyeIcon"
                    class="cursor-pointer ml-1"
                    size="16"
                    @click=" $router.push({ name: 'facture-show', params: { id: props.row.id } })"
                  />
                  <b-tooltip
                    title="Détail"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />

                  <feather-icon
                    :id="`invoice-row-${props.row.id}-edit-icon`"
                    icon="EditIcon"
                    class="cursor-pointer ml-1"
                    size="16"
                    @click=" $router.push({ name: 'facture-edit', params: { id: props.row.id } })"
                  />
                  <b-tooltip
                    title="Modifier"
                    :target="`invoice-row-${props.row.id}-edit-icon`"
                  />
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage 1 à            </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> de {{ count }} entrées
                  </span>
                </div>

                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="count"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="pageNext(currentPage)"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BContainer, BRow, BCol, BCard, BCardTitle, BListGroup, BListGroupItem, BTable, BAvatar, BBadge, BPagination, BFormGroup, BFormSelect, BButton,
  BSpinner, BTooltip, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

export default {
  name: 'CustomerDetail',
  components: {
    VueGoodTable,
    vSelect,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    BBadge,
    BFormInput,
  },
  data() {
    return {
      customer: null,
      num: '',
      awaitingSearch: false,
      clients: [],
      client: { id: '' },
      currentPage: 1,
      count: 0,
      isLoading: 'false',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Numéro',
          field: 'reference',
        },
        {
          label: 'Client',
          field: 'customer',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Payment Status',
          field: 'payment_status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      factures: [],
      stausPayments: [
        { value: 'unpaid', label: 'Non payé' },
        { value: 'paid', label: 'Payé' },
        { value: 'partially_paid', label: 'Partiellement payé' },
      ],
      stausPayment: { value: '', label: '' },
      searchTerm: '',
      paymentFields: [
        { key: 'invoice_reference', label: 'Référence Facture' },
        { key: 'payment_method', label: 'Méthode' },
        { key: 'amount', label: 'Montant' },
        { key: 'cheque_number', label: 'Numéro de Chèque' },
        { key: 'due_date', label: 'date échéance' },
        { key: 'created_at', label: 'Date de création' },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
      }
      return status => statusColor[status]
    },
  },
  watch: {
    num() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getFactures(1)
          this.awaitingSearch = false
        }, 1000) // 1 sec delay
      }
      this.awaitingSearch = true
    },
  },
  created() {
    this.getClients()
    this.getFactures(1)
  },
  mounted() {
    this.fetchCustomerData()
  },
  methods: {
    getPaymentStatusVariant(status) {
      if (status === 'Payé') return 'success'
      if (status === 'Non payé') return 'danger'
      return 'warning'
    },
    async pageNext(currentPage) {
      this.getFactures(currentPage)
    },
    async getClients() {
      this.isLoading = true
      const { data } = await axios.get('/customers/short-list/')
      this.clients = data
      this.isLoading = false
    },
    resetFilters() {
      this.stausPayment = ''
      this.getFactures(1)
    },
    // get factures
    async getFactures(currentPage) {
      this.isLoading = 'true'
      const { data } = await axios.get('/invoices/list/', {
        params: {
          p: currentPage,
          customer: this.$route.params.id,
          payment_status: this.stausPayment.value,
          reference: this.num,
        },
      })
      this.factures = data.results
      this.count = data.count
      this.isLoading = false
    },
    // delete facture
    deleteFacture(devid) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.isLoading = 'true'
        if (result.value) {
          axios.delete(`/invoices/delete/${devid}/`).then(() => {
            this.isLoading = 'false'
            this.getFactures(1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Suppression réussite',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    getRowClass(row) {
      if (row.payment_status === 'Payé') {
        return 'table-success'
      } if (row.payment_status === 'Non payé') {
        return 'table-danger'
      } if (row.payment_status === 'Partiellement payé') {
        return 'table-warning'
      }
      return ''
    },
    async fetchCustomerData() {
      try {
        const response = await axios.get(`customers/${this.$route.params.id}/`)
        this.customer = response.data
      } catch (error) {
        // console.error('Error fetching customer data:', error)
      }
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'TND' }).format(amount)
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.customer-detail {
  padding: 30px 0;
  background-color: #f8f9fa;

  h2, h3 {
    font-weight: 600;
    margin-bottom: 25px;
    color: #3c4858;
  }

  .card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    }

    .card-title {
      font-size: 1.25rem;
      color: #2c3e50;
    }
  }

  .list-group-item {
    border: none;
    padding: 12px 20px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #e9ecef;
    }

    strong {
      color: #34495e;
      margin-right: 10px;
    }
  }

  .payment-table {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;

    thead th {
      background-color: #f1f3f5;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.85rem;
      color: #2c3e50;
    }

    tbody tr {
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #f8f9fa;
      }
    }
  }

  .badge {
    padding: 8px 12px;
    font-weight: 500;
    border-radius: 20px;
  }

  .vue-good-table {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .vgt-table {
      border: none;

      th {
        background-color: #f1f3f5;
        color: #2c3e50;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.85rem;
      }

      td {
        padding: 12px 20px;
      }
    }

    .vgt-wrap__footer {
      background-color: #f8f9fa;
      border-top: 1px solid #e9ecef;
    }
  }

}
</style>
